import React from 'react'
import Seo from '../../../components/Seo'
import BigTitle from '../../../components/BigTitle'
import DefaultSection from '../../../components/DefaultSection'
import Article from '../../../components/Article'
import TeaserSection from '../../../components/TeaserSection'
import TeaserHeader from '../../../components/TeaserHeader'
import IndividualContactSection from '../../../components/IndividualContactSection'


const Page = () => {
  const jobDescriptionText = 'Als IT-Consultant Web Applications Java (m/w/d) bist Du technologisch immer ganze vorne mit ' +
    'dabei und wagst den Blick über den Tellerrand. Du weißt, dass erfolgreiche Frontends nicht ohne innovative Backendlösungen ' +
    'bestehen können und bist bereit, gemeinsam mit uns das nächste Anwendungssystem für unsere Kunden zu kreieren. Nutze ' +
    'die Chance als kommunikativer Charakter mit technologisch fundiertem Background, das nächste „smarte“ System zu bauen, ' +
    'das unsere Kunden den entscheidenden Schritt voranbringt und Deine Endanwender lieben.'
  return (
    <React.Fragment>
      <Seo title="IT-Consultant Web Applications Java (m/w/d)"
           description="Du bist auf der Suche nach einem spannenden Job in einem innovativen IT-Unternehmen? Bewirb Dich jetzt als IT-Consultant Web Applications Java (m/w/d) und werde Teil unseres Teams!"
           jobTitle="IT-Consultant Web Applications Java (m/w/d)"
           jobDescription={jobDescriptionText}
           jobIdentifier="itc-wa-mwd" jobDatePosted="2021-04-14"
      />
      <BigTitle title="Stellenangebot" imageRelativePath={'Titelbild_Karriere.jpg'} className="mask-black"
                textInverted={true}
                menuInverted={true} logoInverted={true}>Du bist auf der Suche nach einem
        spannenden Job in einem innovativen IT-Unternehmen? Dann bist Du hier genau richtig.</BigTitle>

      <DefaultSection>
        <Article>
          <h1>IT-Consultant Web Applications Java (m/w/d)</h1>
          <br/>
          <h2>Deine Aufgabe</h2>
          <p>{jobDescriptionText}</p>
          <h2>Dein Profil</h2>
          <p>
            <ul>
              <li>Du hast ein abgeschlossenes Hochschulstudium in der Informatik oder eines verwandten Studienganges.
              </li>
              <li>In der Vergangenheit hast Du bereits Erfahrungen in der Web-Entwicklung gesammelt.</li>
              <li>Angular und Spring-Boot sind für Dich keine Fremdwörter und idealerweise hast Du schon einmal mit
                einer Graphendatenbank gearbeitet.
              </li>
              <li>Neben sehr guten Deutschkenntnissen (mind. C1 Niveau) und guten Englischkenntnissen besitzt Du die
                Fähigkeit, Dich schnell in neue Aufgabenfelder und Technologien einzuarbeiten.
              </li>
              <li>Du bist ein teamfähiger und engagierter Mensch, der strukturiert arbeitet und Verantwortung
                übernimmt.
              </li>
            </ul>
          </p>
          <p> Je nach Berufserfahrung steigst Du bei uns in unsere internen oder in Kunden-Projekte ein und wirst zu
            Beginn von einem erfahrenen Kollegen begleitet.</p>
          <h2>Deine Benefits bei BUSCHMAIS</h2>
          <p>
            <ul>
              <li>Bei uns findest Du ein innovatives Umfeld, in dem Du Dich in der täglichen Praxis, mittels Schulungen
                oder Konferenzbesuchen fachlich weiterentwickelst.
              </li>
              <li>Wir legen viel Wert auf die Vereinbarkeit von Privat- und Berufsleben.</li>
              <li>Uns ist eine direkte Kommunikation enorm wichtig, weswegen die Entscheidungswege so kurz wie nur
                möglich gehalten sind.
              </li>
              <li>Bei uns verdurstet niemand. Kaffee und Tee aus dem Bioladen und frisch gefiltertes Wasser sind
                kostenfrei.
              </li>
              <li>Auch nach Feierabend haben wir ein offenes Ohr - ganz gleich, ob privater oder beruflicher
                Gesprächsstoff.
              </li>
              <li>Langeweile kommt bei uns nicht auf, denn wir veranstalten Ausflüge und Grillabende und nehmen an
                Sportevents teil.
              </li>
            </ul>
          </p>
        </Article>
      </DefaultSection>

      <TeaserSection className="bg-level-1" anchor="bewerben">
        <TeaserHeader title="Begeistert?">
          <p>
            Das freut uns. Sende uns bitte Deine vollständigen Bewerbungsunterlagen, ggf. mit Deinen
            Gehaltsvorstellungen und dem frühestmöglichen Einstiegtermin per E-Mail. Für Rückfragen stehen wir Dir gerne
            zur Verfügung.
          </p>
          <p>Bitte beachte, dass Bewerbungen über die E-Mail-Dienste
            Outlook und Gmail aus technischen Gründen ggf. nicht bei uns ankommen.</p>
          <p className="mt-5">
            <a className="btn btn-outline-black" href="mailto:jobs@buschmais.com"
               title="Jetzt per Mail bewerben">Jetzt per Mail bewerben</a>
          </p>
        </TeaserHeader>
      </TeaserSection>

      <IndividualContactSection personKey={'christiane'} email={'jobs@buschmais.com'} tel={'+49 351 320923 11'}/>

    </React.Fragment>
  )
}

export default Page
